import { useNavigate } from "react-router-dom";
export default function PageIntro(props) {
  const { miniTitle, title, messageContent, pageName, buttonsVisible } = props;

  const navigate = useNavigate();

  function goBookApptPage() {
    navigate("/book-appointment");
    window.scrollTo(0, 0);
  }

  return (
    <div className="page-intro-container">
      <div className="center-content">
        <div className="content-container">
          <div className="title-container">
            <div className="lis-nails-title">{miniTitle}</div>
            <div className="page-title">{title}</div>
          </div>
          <div className="intro-container">{messageContent}</div>
          {buttonsVisible && (
            <div className="buttons-container">
              <button
                className="book-now-button"
                onClick={() => {
                  goBookApptPage();
                }}
              >
                Book Now
              </button>
              <a href="#faq-anchor">
                <button
                  className="faq-button"
                  onClick={() => {
                    navigate("/cancel-appointment");
                  }}
                >
                  Frequently Asked Questions
                </button>
              </a>
            </div>
          )}
        </div>
        <div className="page-intro-image-container">
          {pageName === "book" && (
            <img
              className="page-intro-img"
              src={require("../../assets/images/working.png")}
              alt="company-logo"
            />
          )}

          {pageName === "home" && (
            <img
              className="page-intro-img"
              src={require("../../assets/images/manicure-home-image.png")}
              alt="company-logo"
            />
          )}
          {pageName === "cancel" && (
            <img
              className="page-intro-img"
              src={require("../../assets/images/cancel-appt.png")}
              alt="company-logo"
            />
          )}
          {pageName === "services" && (
            <img
              className="page-intro-img"
              src={require("../../assets/images/services.png")}
              alt="company-logo"
            />
          )}
        </div>
      </div>
    </div>
  );
}
