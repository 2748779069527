import { PageIntro, ServiceOffered, TittleCard } from "../../components";
import { services } from "../../utils/variables";

export default function ServicesOffered() {
  function buildOptions(services) {
    let options = [];
    let p = services;
    for (var key in p) {
      if (p.hasOwnProperty(key)) {
        let item = p[key];

        options.push(
          <ServiceOffered
            key={key}
            title={item.displayName}
            price={item.price}
            description={item.description}
          />
        );
      }
    }

    return options;
  }

  return (
    <div className="services-offered-page">
      <PageIntro
        miniTitle={"Best-qualified products"}
        title={"Services Offered"}
        messageContent={
          "Our goal is to bring customers the best service quality and the most satisfaction!"
        }
        pageName="services"
        buttonsVisible={true}
      />
      <div className="services-offered-container">
        <TittleCard title={"Acrylics"} />
        <div className="offered-cards-container">
          {buildOptions(services.nailServices.acrylicServices)}
        </div>

        <TittleCard title={"Dip Powder"} />
        <div className="offered-cards-container">
          {buildOptions(services.nailServices.dipPowderServices)}
        </div>

        <TittleCard title={"Gel X"} />
        <div className="offered-cards-container">
          {buildOptions(services.nailServices.gelXServices)}
        </div>

        <TittleCard title={"Rubber"} />
        <div className="offered-cards-container">
          {buildOptions(services.nailServices.rubberServices)}
        </div>

        <TittleCard title={"Manicure"} />
        <div className="offered-cards-container">
          {buildOptions(services.nailServices.manicures)}
        </div>

        <TittleCard title={"Regular Polish Changes"} />
        <div className="offered-cards-container">
          {buildOptions(services.nailServices.polishChanges.regularPolish)}
        </div>

        <TittleCard title={"Gel Polish Changes"} />
        <div className="offered-cards-container">
          {buildOptions(services.nailServices.polishChanges.gelPolish)}
        </div>

        <TittleCard title={"Add Ons - Nails"} />
        <div className="offered-cards-container">
          {buildOptions(services.addOnServices.nail)}
        </div>

        <TittleCard title={"Add Ons - Pedicure"} />
        <div className="offered-cards-container">
          {buildOptions(services.addOnServices.pedicure)}
        </div>
        <TittleCard title={"Pedicure"} />
        <div className="offered-cards-container">
          {buildOptions(services.pedicureServices.pedicures)}
        </div>
        <TittleCard title={"Pedicure - Regular Polish Changes"} />
        <div className="offered-cards-container">
          {buildOptions(services.pedicureServices.polishChange.regularPolish)}
        </div>
        <TittleCard title={"Pedicure - Gel Polish Changes"} />
        <div className="offered-cards-container">
          {buildOptions(services.pedicureServices.polishChange.gelPolish)}
        </div>
        <TittleCard title={"Kids Regular Polish - under 11 years old"} />
        <div className="offered-cards-container">
          {buildOptions(services.kidServices.regularPolish)}
        </div>
        <TittleCard title={"Kids Gel Polish - under 11 years old"} />
        <div className="offered-cards-container">
          {buildOptions(services.kidServices.gelPolish)}
        </div>
        <TittleCard title={"Kids Regular Polish - under 11 years old"} />
        <div className="offered-cards-container">
          {buildOptions(services.kidServices.polishChanges.regularPolish)}
        </div>
        <TittleCard title={"Kids Gel Changes - under 11 years old"} />
        <div className="offered-cards-container">
          {buildOptions(services.kidServices.polishChanges.gelPolish)}
        </div>
        <TittleCard title={"Men - Pedicure"} />
        <div className="offered-cards-container">
          {buildOptions(services.menServices.pedicure)}
        </div>
        <TittleCard title={"Men - Manicure"} />
        <div className="offered-cards-container">
          {buildOptions(services.menServices.manicure)}
        </div>
        <TittleCard title={"Soak Off"} />
        <div className="offered-cards-container">
          {buildOptions(services.soakOffServices)}
        </div>
      </div>
    </div>
  );
}
