import { useNavigate } from "react-router-dom";
export default function SplashBox() {
  const navigate = useNavigate();

  function goBookApptPage() {
    navigate("/book-appointment");
    window.scrollTo(0, 0);
  }

  return (
    <div className="splash-box-container">
      <div className="circle circle-one"></div>
      <div className="circle circle-two"></div>
      <div className="circle-three"></div>

      <div className="splash-box-message">
        <h3>Service Guarantee!</h3>
        <p>
          Lis Nails & Spa provides an 8-day guarantee period on gel, dip
          powder, & artificial services in which you may return to the salon to
          have repairs done free of charge based on same color.
        </p>
      </div>
      <div className="splash-box-button-container">
        <button
          onClick={() => {
            goBookApptPage();
          }}
        >
          Book appointment
        </button>
      </div>
    </div>
  );
}
