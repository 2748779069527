import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { functions } from "../../Firebase";
import ErrorPopup from "../error-popup";
import LoadingSpinner from "../loading-spinner";
import SuccessPopup from "../success-popup";
import ExistingAppointmentWarning from "./existing-appointments-warning";

export default function TimeOffForm(props) {
  const { hideForm, updateCalendar } = props;
  const [timeOffList, setTimeOffList] = useState([]);
  const [day, setDay] = useState("");
  const [startTimeOff, setStartTimeOff] = useState("default");
  const [hoursOff, setHoursOff] = useState("default");
  const [inputError, setInputError] = useState(false);
  const [todayDate, setTodayDate] = useState(null);
  const [message, setMessage] = useState("");
  const [timeOffLoading, setTimeOffLoading] = useState(false);
  const [timeOffError, setTimeOffError] = useState(false);
  const [timeOffSuccess, setTimeOffSuccess] = useState(false);
  const [existingAppointments, setExistingAppointments] = useState([]);

  const addTimeOffAPI = httpsCallable(functions, "addTimeOff");

  function closePopup() {
    setHoursOff("default");
    setStartTimeOff("default")
    setTimeOffList([]);
    setDay("");
    setInputError(false);
    setMessage("");
    setTimeOffLoading(false);
    setTimeOffError(false);
    setTimeOffSuccess(false);
    setExistingAppointments([]);
    getTodayDate();
  }

  /**
   * Building initial information.
   */
  useEffect(() => {
    getTodayDate();
  }, []);

  /**
   * Creates the current date object.
   */
  function getTodayDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    setTodayDate(yyyy + "-" + mm + "-" + dd);
  }

  /**
   * Call cloud function to add time off.
   */
  async function submitTimeOff() {
    if (timeOffList.length === 0) {
      setMessage("You must add time off.");
      setInputError(true);
    } else {
      setTimeOffLoading(true);
      await addTimeOffAPI(timeOffList)
        .then((response) => {
          setTimeOffLoading(false);
          setMessage(
            "Request time off has been added to your calendar successfully!"
          );
          setTimeOffSuccess(true);
          setExistingAppointments(response.data);
          updateCalendar([], []);
        })
        .catch((error) => {
          setTimeOffLoading(false);
          if (error.code === "functions/already-exists") {
            setMessage(
              error.message +
                "Please select another time, or cancel the existing time off."
            );
          }

          setTimeOffError(true);
        });
    }
  }

  /**
   * Adds the time off to be requested into a list.
   */
  function addTimeOff() {
    if (validateForm()) {
      setMessage("All fields must have a selection.");
      setInputError(true);
    } else if (checkDuplicate(day, startTimeOff, hoursOff)) {
      setMessage("There are duplicates dates and starting times.");
      setInputError(true);
    } else {
      setInputError(false);
      let times = [...timeOffList];
      times.push({
        day,
        hoursOff,
        startTimeOff,
      });
      setTimeOffList([...times]);
    }
  }

  /**
   * Deletes the given index from the list.
   * @param {index} index
   */
  function removeItem(index) {
    let tempTimesOff = [...timeOffList];
    tempTimesOff.splice(index, 1);
    setTimeOffList([...tempTimesOff]);
  }

  /**
   * Checks the times selected is not already added to the list.
   * @param {selectedDay} selectedDay
   * @param {selectedTime} selectedTime
   * @returns
   */
  function checkDuplicate(selectedDay, startTimeOff, selectedTime) {
    let duplicate = false;

    if (timeOffList.length !== 0) {
      for (let timeOff of timeOffList) {
        if (
          timeOff.hoursOff === selectedTime &&
          selectedDay === timeOff.day &&
          startTimeOff === timeOff.startTimeOff
        ) {
          duplicate = true;
          break;
        }
        if (
          selectedDay === timeOff.day &&
          timeOff.hoursOff === "all-day-off" &&
          (selectedTime === "morning-off" || selectedTime === "afternoon-off")
        ) {
          duplicate = true;
          break;
        }
      }
    }

    return duplicate;
  }

  /**
   * Checks if any of the fields have been selected.
   * @returns
   */
  function validateForm() {
    return day === "" || hoursOff === "default" || startTimeOff === "default";
  }
  return (
    <div className="time-off-container">
      {timeOffSuccess && existingAppointments.length !== 0 && (
        <ExistingAppointmentWarning
          appointments={existingAppointments}
          closeFunction={closePopup}
        />
      )}
      {timeOffSuccess && existingAppointments.length === 0 && (
        <SuccessPopup
          message={"Your time off has been registered. Thank you!"}
          closePopup={closePopup}
        />
      )}
      {timeOffError && <ErrorPopup message={message} closePopup={closePopup} />}
      {timeOffLoading && <LoadingSpinner message="Loading..." />}

      <div className="time-off-title">
        {/* <img
          className="footer-logo-img"
          src={require("../../assets/images/vacation-2.png")}
          alt="calendar-icon"
        /> */}
        <h3>Register Time Off</h3>
        <img
          className="footer-logo-img"
          src={require("../../assets/images/vacation-1.png")}
          alt="calendar-icon"
        />
      </div>
      <div className="time-off-form">
        <div className="time-off-field-container">
          <label>Select a day: </label>
          {todayDate && (
            <input
              type="date"
              min={todayDate}
              value={day}
              onChange={(event) => {
                setDay(event.target.value);
              }}
            />
          )}
        </div>
        <div className="time-off-field-container">
          <label>Start Time</label>
          <select
            value={startTimeOff}
            onChange={(event) => {
              setStartTimeOff(event.target.value);
            }}
          >
            <option value="default">Select...</option>
            <option value={9}>9:00 AM</option>
            <option value={10}>10:00 AM</option>
            <option value={11}>11:00 AM</option>
            <option value={12}>12:00 AM</option>
            <option value={13}>1:00 PM</option>
            <option value={14}>2:00 PM</option>
            <option value={15}>3:00 PM</option>
            <option value={16}>4:00 PM</option>
            <option value={17}>5:00 PM</option>
            <option value={18}>6:00 PM</option>
          </select>
        </div>

        <div className="time-off-field-container">
          <label>Hours Off</label>
          <select
            value={hoursOff}
            onChange={(event) => {
              setHoursOff(event.target.value);
            }}
          >
            <option value="default">Select...</option>
            <option value={10}>All Day</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
            <option value={7}>7</option>
            <option value={8}>8</option>
            <option value={9}>9</option>
            
          </select>
        </div>
        <div className="time-off-button-container">
          <button
            className="add-button"
            onClick={() => {
              addTimeOff();
            }}
          >
            Add
          </button>
        </div>
      </div>
      <div className="time-off-error">
        {inputError && <label>{message}</label>}
      </div>

      <div className="days-selected-display">
        {timeOffList.length !== 0 && (
          <table>
            <tbody>
              <tr>
                <th>Day</th>
                <th>Start Time</th>
                <th>Hours Off</th>
                <th>Delete</th>
              </tr>
              {timeOffList.map((timeOff, index) => {
                return (
                  <tr key={index}>
                    <td>{timeOff.day}</td>
                    <td>
                      {timeOff.startTimeOff > 12
                        ? timeOff.startTimeOff - 12
                        : timeOff.startTimeOff}{" "}
                      -{timeOff.startTimeOff >= 12 ? "PM" : "AM"}{" "}
                    </td>
                    <td>{timeOff.hoursOff}</td>
                    <td>
                      {" "}
                      <img
                        onClick={() => {
                          removeItem(index);
                        }}
                        className="footer-logo-img"
                        src={require("../../assets/images/trash-can.png")}
                        alt="trash-can-icon"
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>

      <div className="time-off-buttons">
        <button
          onClick={() => {
            submitTimeOff();
          }}
          className="submit-button"
        >
          Submit time off
        </button>
        <button
          className="cancel-button"
          onClick={() => {
            hideForm();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
