import { signOut } from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { auth } from "../../Firebase";
import userAtom from "../../recoil/atoms/user";
import LoadingSpinner from "../loading-spinner";
import appointmentAtom from "../../recoil/atoms/appointments"
import dateRangesAtom from "../../recoil/atoms/date-ranges";

export default function LoginButton(props) {
  const cssShowNavButtons = props;

  const [user, setUser] = useRecoilState(userAtom);
  const userValue = useRecoilValue(userAtom);
  const [signUserOutLoading, setSignUserOutLoading] = useState(false);
  const setAppointments = useSetRecoilState(appointmentAtom)
  const setDateRanges = useSetRecoilState(dateRangesAtom)

  /**
   * Sing user out from google authentication.
   */
  async function userSignOut() {
    setSignUserOutLoading(true);
    await signOut(auth)
      .then(() => {
        
        // Sign-out successful.
        setUser(null);
        setDateRanges([])
        setAppointments([])
        navigate("/login");
        setSignUserOutLoading(false)
      })
      .catch((error) => {
        setSignUserOutLoading(false);
        // An error happened.
      });
  }

  function handleLoginLogout() {
    if (user !== null) {
      userSignOut();
    } else {
      navigate("/login");
    }
  }

  const navigate = useNavigate();
  return (
    <div
      className={`login-button-container ${
        cssShowNavButtons ? "login-button-hamburger-style" : ""
      }`}
    >
      {signUserOutLoading && <LoadingSpinner message="Signing out..." />}
      <button className={`login-button`} onClick={() => handleLoginLogout()}>
        {userValue !== null ? "Logout" : "Login"}
      </button>
    </div>
  );
}
